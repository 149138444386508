import './styles.css';
import { initializeVAD, startVAD, stopVAD } from './vad.processor.mjs';
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import './singleTabManager.mjs';

console.log('[Socket] Attempting connection to https://api.augnos.ch');
let connectCounter = 0;
const socket = io('https://api.augnos.ch', {
  transports: ['websocket'],
  upgrade: false,
  withCredentials: true
});

// Socket connection events
socket.on('connect', () => {
  connectCounter++;
  console.log(`[Socket] Connect event #${connectCounter}, id=${socket.id}`);
  console.log('[Socket] Connected successfully');
});
socket.on('connect_error', (error) => {
  console.error('[Socket] Connection FAILED:', {
    error: error.message,
    description: error.description,
    type: error.type,
    context: error.context,
    transportType: socket.io.engine?.transport?.name || 'no transport'
  });
});
socket.on('disconnect', (reason) => {
  console.log('[Socket] Disconnected:', reason);
});

// Immediate authentication check (route protection)
(async function () {
  if (window.location.search.includes('code=')) return;
  try {
    const resp = await fetch('https://api.augnos.ch/auth/userinfo', { credentials: 'include' });
    if (!resp.ok) {
      window.location.href = 'https://api.augnos.ch/auth/login';
    }
  } catch (error) {
    console.error('[Frontend] Session check error:', error);
    window.location.href = 'https://api.augnos.ch/auth/login';
  }
})();

// Global UI variables and state flags
let startButton, stopButton, clearButton, statusText, statusIndicator, transcriptionContainer;
let activePartialRow = null;
let isArmed = false;         // True once "Listen" is clicked; remains until Stop is clicked.
let isTranscribing = false;    // True when a transcription session is active.
let timerInterval;
let startTime;
let currentRemainingTime;
let isActivelyTranscribing = false;
let elapsedTimeBeforePause = 0;

// UI update function based on flags
function updateButtonStates() {
  if (startButton) startButton.disabled = isArmed;   // Remains disabled if armed.
  if (stopButton) stopButton.disabled = !isArmed;        // Stop is enabled only if armed.
  if (clearButton) {
    const transcriptRows = document.querySelectorAll('.transcript-row');
    clearButton.disabled = isTranscribing || transcriptRows.length === 0;
  }
}
window.updateButtonStates = updateButtonStates;

function updateStatus(status) {
  console.log('Status updated:', status);
  if (statusText) statusText.textContent = status;
  if (statusIndicator) {
    statusIndicator.className = (status === 'Listening ...' ? 'connection-status connected' : 'connection-status disconnected');
  }
}
window.updateStatus = updateStatus;

function clearTranscript() {
  console.log('Clear button clicked');
  if (transcriptionContainer) {
    transcriptionContainer.innerHTML = '';
  }
  updateButtonStates();
}
window.clearTranscript = clearTranscript;

// Transcription event handling remains unchanged
socket.on('transcription', data => {
  console.log(`[Transcription] Received ${data.isFinal ? 'final' : 'partial'} transcription:`, {
    length: data.text.length,
    speaker: data.speaker,
    preview: data.text.substring(0, 50) + '...'
  });
  if (!data.isFinal) {
    if (!activePartialRow) {
      const rowDiv = document.createElement('div');
      rowDiv.classList.add('partial-row');
      const textCol = document.createElement('div');
      textCol.classList.add('partial-text-col');
      textCol.textContent = data.text;
      rowDiv.appendChild(textCol);
      transcriptionContainer.appendChild(rowDiv);
      activePartialRow = { rowDiv, textCol };
    } else {
      activePartialRow.textCol.textContent = data.text;
    }
    transcriptionContainer.scrollTop = transcriptionContainer.scrollHeight;
    return;
  }
  if (activePartialRow) {
    transcriptionContainer.removeChild(activePartialRow.rowDiv);
    activePartialRow = null;
  }
  const rowDiv = document.createElement('div');
  rowDiv.classList.add('transcript-row');
  
  // New: Apply alternating color based on metadata.chunkNumber
  if (data.metadata && typeof data.metadata.chunkNumber === 'number') {
      if (data.metadata.chunkNumber % 2 === 0) {
          rowDiv.classList.add('color-a'); // even chunk number → color-a
      } else {
          rowDiv.classList.add('color-b'); // odd chunk number → color-b
      }
  }
  
  const speakerCol = document.createElement('div');
  speakerCol.classList.add('speaker-col');
  speakerCol.textContent = data.speaker || '';
  const textCol = document.createElement('div');
  textCol.classList.add('text-col');
  textCol.textContent = data.text;
  rowDiv.appendChild(speakerCol);
  rowDiv.appendChild(textCol);
  
  transcriptionContainer.appendChild(rowDiv);
  transcriptionContainer.scrollTop = transcriptionContainer.scrollHeight;
  console.log(`[UI] Rendering final row => speaker: '${data.speaker || ''}'`);
});

socket.on('error', errorMessage => {
  console.error('Server error:', errorMessage);
  if (errorMessage.code === 'MAX_CONCURRENCY_REACHED') {
    console.warn('Concurrency limit reached');
    updateStatus('System busy - please try again later');
    stopAudioFlow(true);
  } else if (errorMessage.includes('timed out')) {
    console.log('Attempting to restart transcription...');
    stopAudioFlow(true);
    setTimeout(() => {
      if (confirm('Transcription timed out. Would you like to restart?')) {
        startAudioFlow();
      }
    }, 1000);
  }
});

// Load audio configuration
async function loadAudioConfig() {
  try {
    const response = await fetch('https://api.augnos.ch/api/config/audio');
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const config = await response.json();
    window.AUDIO_CONFIG = config;
    console.log('[Config] Loaded audio configuration:', config);
  } catch (error) {
    console.error('[Config] Failed to load audio configuration from server:', error);
    throw error;
  }
}

// Audio conversion helpers
function audioBufferToPCM(inputData) {
  const max = Math.max(...inputData.map(Math.abs));
  if (max > 0.1) {
    console.log(`[Audio] Significant audio activity detected: max amplitude ${max.toFixed(3)}`);
  }
  const int16Array = new Int16Array(inputData.length);
  for (let i = 0; i < inputData.length; i++) {
    const s = Math.max(-1, Math.min(1, inputData[i]));
    int16Array[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
  }
  return int16Array.buffer;
}

function createQuietChunk(durationMs = 100) {
  const sampleRate = window.AUDIO_CONFIG ? window.AUDIO_CONFIG.SAMPLE_RATE : 16000;
  const frameCount = Math.floor(sampleRate * (durationMs / 1000));
  const buffer = new Int16Array(frameCount);
  return buffer.buffer;
}

// Audio flow functions
async function startAudioFlow() {
  console.log('[Consolidated] startAudioFlow triggered.');
  try {
    // Initialize timer before anything else
    await initializeTimer();
    
    // When "Listen" is clicked, arm the system.
    isArmed = true;
    updateButtonStates();
    
    await loadAudioConfig();
    const constraints = {
      audio: {
        sampleRate: { ideal: window.AUDIO_CONFIG.SAMPLE_RATE },
        channelCount: { ideal: 1 },
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true
      }
    };
    // MediaStream Acquisition: single call to getUserMedia
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    window.microphoneStream = stream;
    console.log('[Consolidated] Microphone access granted.');

    // Define the new onSpeechRealStart callback
    const onSpeechStart = () => {
      console.log('🎯 [App] onSpeechStart callback in app.mjs RECEIVED!');

      // Clear any pending silence timeout
      if (window.silenceTimeout) {
        console.log('🧹 [App] Clearing existing silence timeout');
        clearTimeout(window.silenceTimeout);
        window.silenceTimeout = null;
      }

      // Only start transcription if not already transcribing
      if (!isTranscribing) {
        console.log('📣 [App] Starting transcription! isTranscribing=false → true');
        isTranscribing = true;
        console.log('📡 [App] Emitting startTranscription socket event');
        socket.emit('startTranscription');
        updateStatus('Listening ...');
        
        // Start the timer when we begin transcribing
        isActivelyTranscribing = true;
        startTimer();
      } else {
        console.log('ℹ️ [App] Already transcribing, no action needed');
        // Make sure timer is running if we're transcribing
        isActivelyTranscribing = true;
      }

      updateButtonStates();
    };

    const onSpeechEnd = () => {
      console.log('[Consolidated VAD] Speech ended.');
      console.log('[Consolidated VAD] Extended silence detected. Sending quiet chunk.');
      socket.emit('audioData', createQuietChunk());
      socket.emit('stopTranscription');
      updateStatus('Not listening');
      
      // Stop timer when we stop transcribing
      isActivelyTranscribing = false;
      stopTimer();
      
      // For automatic stop, keep isArmed true so "Listen" remains disabled.
      isTranscribing = false;
      updateButtonStates();
    };

    const onAudioBuffer = (audioBuffer) => {
      socket.emit('audioData', audioBufferToPCM(audioBuffer));
    };

    // VAD Initialization using the unified MediaStream
    const vadInitialized = await initializeVAD(
      stream,
      onSpeechStart,   // explicitly correct now
      onSpeechEnd,
      onAudioBuffer
    );
    if (!vadInitialized) {
      console.error('[Consolidated] VAD initialization failed.');
      updateStatus('VAD initialization failed');
      return;
    }
    console.log('[Consolidated] VAD armed.');
    startVAD();
    console.log('[Consolidated] VAD started and now monitoring for speech.');
    updateStatus('Ready for speech...');
    updateButtonStates();
  } catch (err) {
    console.error('[Consolidated] Error in startAudioFlow:', err);
    updateStatus('Audio flow error: ' + err.message);
  }
}

function stopAudioFlow(manual = false) {
  console.log('[Consolidated] stopAudioFlow triggered.');
  
  if (window.silenceTimeout) {
    clearTimeout(window.silenceTimeout);
    window.silenceTimeout = null;
  }
  if (isTranscribing) {
    console.log('[Consolidated] Sending quiet chunk due to stop.');
    socket.emit('audioData', createQuietChunk());
  }
  
  stopVAD();
  if (window.microphoneStream) {
    window.microphoneStream.getTracks().forEach(track => track.stop());
    window.microphoneStream = null;
    console.log('[Consolidated] Microphone stopped.');
  }
  
  socket.emit('stopTranscription');
  updateStatus('Not listening');
  isTranscribing = false;
  if (manual) {
    isArmed = false;
  }
  updateButtonStates();
}
window.startAudioFlow = startAudioFlow;
window.stopAudioFlow = stopAudioFlow;

// Check microphone permission
async function checkMicrophonePermission() {
  try {
    console.log('[MicPermission] Starting permission check...');
    const permissionResult = await navigator.permissions.query({ name: 'microphone' });
    console.log('[MicPermission] Initial state:', permissionResult.state);
    permissionResult.addEventListener('change', () => {
      console.log('[MicPermission] State changed to:', permissionResult.state);
      document.getElementById('connectionText').textContent =
        `Microphone permission: ${permissionResult.state}`;
    });
    console.log('[MicPermission] Check completed. Success:', permissionResult.state === 'granted');
    return { success: permissionResult.state === 'granted', state: permissionResult.state };
  } catch (err) {
    console.error('[MicPermission] Check failed:', err);
    return { success: false, error: err };
  }
}
window.checkMicrophonePermission = checkMicrophonePermission;

// Sign-out functionality
async function handleSignOut() {
  console.log('[Frontend] handleSignOut triggered. Clearing backend session...');
  await fetch('https://api.augnos.ch/auth/logout', { credentials: 'include' });
  const response = await fetch('https://api.augnos.ch/auth/config');
  const { clientId } = await response.json();
  window.location.href = `https://auth.augnos.ch/logout?client_id=${clientId}&logout_uri=https://augnos.ch`;
}

// UI and initialization setup on DOMContentLoaded
document.addEventListener('DOMContentLoaded', async () => {
  console.log('[DOMContentLoaded] Event triggered');
  // Generate unique tab ID for this tab
  window.tabId = Date.now().toString() + Math.random().toString(36).substring(2, 15);
  console.log('[Consolidated] Tab ID created:', window.tabId);
  startButton = document.getElementById('startButton');
  stopButton = document.getElementById('stopButton');
  clearButton = document.getElementById('clearButton');
  statusText = document.getElementById('connectionText');
  statusIndicator = document.getElementById('connectionLight');
  transcriptionContainer = document.getElementById('transcriptionContainer');

  clearButton.addEventListener('click', clearTranscript);
  // Clicking "Listen" starts the audio flow and arms the system.
  startButton.addEventListener('click', startAudioFlow);
  // Clicking "Stop" triggers a manual stop that disarms the system.
  stopButton.addEventListener('click', () => {
    stopAudioFlow(true);
  });

  const signOutButton = document.getElementById('signOutButton');
  if (signOutButton) {
    signOutButton.addEventListener('click', event => {
      event.preventDefault();
      handleSignOut();
    });
  }

  if (!navigator.cookieEnabled) {
    document.body.innerHTML = '<div class="error-message">Please enable cookies to use this application.</div>';
    return;
  }

  try {
    console.log('[DOMContentLoaded] Checking user info...');
    const resp = await fetch('https://api.augnos.ch/auth/userinfo', { credentials: 'include' });
    if (resp.ok) {
      const data = await resp.json();
      const usernameElement = document.getElementById('username');
      const remainingTimeElement = document.getElementById('remaining-time');
      if (usernameElement && data.username) {
        usernameElement.textContent = data.username;
      }
      if (remainingTimeElement && data.remaining) {
        const hours = Math.floor(data.remaining / 3600);
        const minutes = Math.floor((data.remaining % 3600) / 60);
        const seconds = Math.floor(data.remaining % 60);
        remainingTimeElement.textContent =
          `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
      console.log('[Frontend] User info and time fetch: success.');
    }
  } catch (error) {
    console.error('[Frontend] Error fetching user info:', error);
  }

  try {
    console.log('[DOMContentLoaded] Checking microphone permissions');
    await checkMicrophonePermission();
  } catch (error) {
    console.log('[DOMContentLoaded] Microphone check error:', error);
    updateStatus('Microphone permission error');
  }

  console.log('[Frontend] Finished DOMContentLoaded setup.');
});

async function initializeTimer() {
    try {
        console.log("[Timer] Fetching user info from /auth/userinfo");
        
        // Use absolute URL to ensure we're hitting the correct endpoint
        const response = await fetch('https://api.augnos.ch/auth/userinfo', {
            credentials: 'include', // Ensure cookies are sent
            headers: {
                'Accept': 'application/json' // Explicitly request JSON
            }
        });
        
        // Log raw response details before parsing
        console.log('[Timer] Response status:', response.status);
        console.log('[Timer] Response headers:', 
            Object.fromEntries(response.headers.entries()));
        
        // Get raw text first to debug potential parsing issues
        const rawText = await response.text();
        console.log('[Timer] Raw response:', rawText);
        
        // Only try to parse if it looks like JSON
        let userData;
        if (rawText && rawText.trim().startsWith('{')) {
            try {
                userData = JSON.parse(rawText);
                console.log('[Timer] Parsed user data:', userData);
                
                // Safely convert to number with fallback
                currentRemainingTime = Number(userData.remaining) || 360000; // Default 100 hours
                console.log('[Timer] Current remaining time set to:', currentRemainingTime);
                
                // Update display
                updateTimerDisplay(currentRemainingTime);
            } catch (parseError) {
                console.error('[Timer] JSON parse error:', parseError);
                // Use default time if parsing fails
                currentRemainingTime = 360000; // Default 100 hours
                updateTimerDisplay(currentRemainingTime);
            }
        } else {
            console.error('[Timer] Response is not valid JSON:', rawText);
            // Use default time if not JSON
            currentRemainingTime = 360000; // Default 100 hours
            updateTimerDisplay(currentRemainingTime);
        }
    } catch (error) {
        console.error('[Timer] Fetch error:', error);
        // Use default time if fetch fails
        currentRemainingTime = 360000; // Default 100 hours
        updateTimerDisplay(currentRemainingTime);
    }
}

function startTimer() {
    console.log("Starting timer with remaining seconds:", currentRemainingTime);
    startTime = Date.now();
    elapsedTimeBeforePause = 0;
    
    timerInterval = setInterval(() => {
        if (isActivelyTranscribing) {
            const currentElapsed = Math.floor((Date.now() - startTime) / 1000) + elapsedTimeBeforePause;
            const newRemaining = Math.max(0, currentRemainingTime - currentElapsed);
            console.log("Timer update: elapsed=", currentElapsed, "remaining=", newRemaining);
            updateTimerDisplay(newRemaining);
        } else {
            console.log("Timer paused: isActivelyTranscribing=false");
        }
    }, 1000);
}

async function stopTimer() {
    clearInterval(timerInterval);
    
    if (!startTime) return; // Already stopped
    
    const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000) + elapsedTimeBeforePause;
    
    try {
        // Fix: Use absolute URL instead of relative
        const response = await fetch('https://api.augnos.ch/auth/update-time', {
            method: 'POST',
            credentials: 'include', // Ensure cookies are sent
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ elapsed: elapsedSeconds })
        });
        
        if (response.ok) {
            const data = await response.json();
            currentRemainingTime = data.remaining; // Get updated server time
            updateTimerDisplay(currentRemainingTime);
        } else {
            console.error("Failed to update time, server responded with:", response.status);
        }
    } catch (error) {
        console.error("Failed to update time:", error);
    } finally {
        // Reset state regardless of success
        startTime = null;
        elapsedTimeBeforePause = 0;
        isActivelyTranscribing = false;
    }
}

function handleVisibilityChange() {
    if (document.hidden && isActivelyTranscribing) {
        // Tab hidden - pause timer & record elapsed
        elapsedTimeBeforePause += Math.floor((Date.now() - startTime) / 1000);
        clearInterval(timerInterval);
    } else if (!document.hidden && isActivelyTranscribing) {
        // Tab visible again - restart from new point
        startTime = Date.now();
        startTimer();
    }
}

function onSpeechDetected() {
    // Existing speech detection code
    isActivelyTranscribing = true;
    console.log("Speech detected, timer active");
}

function onSilenceDetected() {
    // Existing silence detection code
    isActivelyTranscribing = false;
    console.log("Silence detected, timer paused");
}

function updateTimerDisplay(remainingSeconds) {
  const hours = Math.floor(remainingSeconds / 3600);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);
  const seconds = Math.floor(remainingSeconds % 60);
  
  const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  
  const remainingTimeElement = document.getElementById('remaining-time');
  if (remainingTimeElement) {
    remainingTimeElement.textContent = timeString;
  }
  console.log("Timer updated:", timeString);
}
