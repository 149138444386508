import { AUDIO_CONFIG } from '../../backend/src/config/audio.config.mjs';

const MicVAD = vad.MicVAD;

// State management
let vadInstance = null;
let isActive = false;
let silenceTimeout = null;
let startCallback = null;
let stopCallback = null;

// Default configuration
const DEFAULT_CONFIG = {
    positiveSpeechThreshold: 0.8,
    negativeSpeechThreshold: 0.3,
    redemptionFrames: 4,
    minSpeechFrames: 3,
    silenceDurationThreshold: 3000,  // 3 seconds
    model: "v5",
    frameSamples: 512,
    preSpeechPadFrames: 1
};

/**
 * Initialize the VAD system with the provided configuration.
 * @param {MediaStream} mediaStream - The external MediaStream to use for VAD.
 * @param {Function} onSpeechStart - Callback triggered immediately when speech is detected.
 * @param {Function} onSpeechEnd - Callback triggered after extended silence.
 * @param {Function} onFrameProcessed - Callback triggered every time an audio frame is processed.
 * @param {Object} config - (Optional) Configuration options to override defaults.
 * @returns {Promise<boolean>} - Resolves to true if VAD initialization is successful, false otherwise.
 */
export async function initializeVAD(mediaStream, onSpeechStart, onSpeechEnd, onFrameProcessed, config = {}) {
    const settings = { ...DEFAULT_CONFIG, ...config };
    const frameProcessedCallback = onFrameProcessed;

    let silenceTimeout = null;

    if (mediaStream) {
        console.log("[VAD] Using externally provided MediaStream:", {
            id: mediaStream.id,
            active: mediaStream.active,
            trackCount: mediaStream.getTracks().length
        });
    } else {
        console.warn("[VAD] No external MediaStream provided. VAD will create its own stream.");
    }

    const vadOptions = {
        baseAssetPath: "https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.22/dist/",
        onnxWASMBasePath: "https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/",
        stream: mediaStream,
        positiveSpeechThreshold: settings.positiveSpeechThreshold,
        negativeSpeechThreshold: settings.negativeSpeechThreshold,
        redemptionFrames: settings.redemptionFrames,
        minSpeechFrames: settings.minSpeechFrames,
        frameSamples: settings.frameSamples,
        preSpeechPadFrames: settings.preSpeechPadFrames,
        model: settings.model,
        workletOptions: {
            numberOfInputs: AUDIO_CONFIG.NUMBER_OF_INPUTS,
            numberOfOutputs: AUDIO_CONFIG.NUMBER_OF_OUTPUTS,
            outputChannelCount: AUDIO_CONFIG.OUTPUT_CHANNEL_COUNT
        },

        onSpeechStart: () => {
            clearTimeout(silenceTimeout);
            silenceTimeout = null;
            if (!isActive && onSpeechStart) {
                isActive = true;
                onSpeechStart();
            }
        },

        onSpeechEnd: () => {
            silenceTimeout = setTimeout(() => {
                if (isActive && onSpeechEnd) {
                    isActive = false;
                    onSpeechEnd();
                }
            }, settings.silenceDurationThreshold);
        },

        onFrameProcessed: (probabilities, frame) => {
            if (frameProcessedCallback) {
                if (probabilities.isSpeech > 0.5) {
                    console.log(`[VAD] Speech detected with probability: ${probabilities.isSpeech.toFixed(2)}`);
                }
                frameProcessedCallback(frame);
            }
        }
    };

    try {
        console.log("[VAD] Initializing with options:", {
            ...vadOptions,
            stream: mediaStream ? "External MediaStream provided" : "No external stream"
        });

        vadInstance = await MicVAD.new(vadOptions);

        if (vadInstance.stream) {
            const isExternalStream = mediaStream && vadInstance.stream.id === mediaStream.id;
            console.log("[VAD] Initialization successful. Stream info:", {
                streamActive: vadInstance.stream.active,
                trackCount: vadInstance.stream.getTracks().length,
                usingExternalStream: isExternalStream,
                trackTypes: vadInstance.stream.getTracks().map(t => t.kind).join(',')
            });
        } else {
            console.warn("[VAD] Initialization successful but no stream is available in VAD instance.");
        }

        return true;
    } catch (error) {
        console.error("[VAD] Error initializing:", error);
        return false;
    }
}

/**
 * Start the VAD system.
 * @returns {boolean} - True on successful start, false otherwise.
 */
export function startVAD() {
    if (vadInstance && !isActive) {
        console.log("[VAD] Starting VAD with stream:", {
            present: !!vadInstance.stream,
            active: vadInstance.stream ? vadInstance.stream.active : false,
            trackCount: vadInstance.stream ? vadInstance.stream.getTracks().length : 0
        });
        vadInstance.start();
        return true;
    }
    return false;
}

/**
 * Stop the VAD system.
 * @returns {boolean} - True on successful stop, false otherwise.
 */
export function stopVAD() {
    if (vadInstance) {
        console.log("[VAD] Stopping VAD processing");
        
        // First pause the VAD processing
        vadInstance.pause();
        
        // Note: We DO NOT stop the MediaStream tracks here anymore.
        // Stream management is now exclusively handled by app.mjs in stopAudioFlow()
        
        isActive = false;
        clearTimeout(silenceTimeout);
        silenceTimeout = null;
        
        // Note: We DO NOT call stopCallback here anymore to prevent circular references
        
        return true;
    }
    return false;
}

/**
 * Check if the VAD is currently active.
 * @returns {boolean} - Active status.
 */
export function isVADActive() {
    return isActive;
}
