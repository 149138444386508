import './styles.css';
import { 
    MediaPermissionsErrorType,
    requestMediaPermissions
} from 'mic-check';

console.log('app.js is loading -->');

// Comment out the immediate route protection
/*
// (async function protectRoute() {
//     console.log('[protectRoute] Starting route protection check');
//     console.log('[protectRoute] Current URL:', window.location.href);
//     console.log('[protectRoute] Cookies:', document.cookie);
    
//     try {
//         console.log('[protectRoute] Attempting to fetch user info');
//         const response = await fetch('https://api.augnos.ch/auth/userinfo', {
//             credentials: 'include'
//         });

//         console.log('[protectRoute] Response status:', response.status);
//         console.log('[protectRoute] Response headers:',
//             Object.fromEntries(response.headers.entries())
//         );

//         if (!response.ok) {
//             console.log('[protectRoute] Response not OK');
//             throw new Error('Not authenticated');
//         }

//         const data = await response.json();
//         console.log('[protectRoute] Successfully authenticated as:', data.username);
//         console.log('[protectRoute] Full user data:', data);
//     } catch (error) {
//         console.log('[protectRoute] Authentication error:', error);
//         console.log('[protectRoute] Error details:', {
//             message: error.message,
//             stack: error.stack
//         });
//         await new Promise(resolve => {
//             console.log('[protectRoute] Delaying redirect for 5 seconds to show errors...');
//             setTimeout(resolve, 5000);
//         });
//         console.log('[Frontend] Not authenticated, redirecting to login...');
//         console.log('[protectRoute] Initiating redirect to login');
//         window.location.href = 'https://api.augnos.ch/auth/login';
//     }
// })();
*/

function updateConnectionStatus(connected) {
    const connectionLight = document.getElementById('connectionLight');
    const connectionText = document.getElementById('connectionText');
    connectionLight.className = connected ? 'connection-status connected' : 'connection-status disconnected';
    connectionText.textContent = connected ? 'Connected' : 'Not Connected';
}

async function handleSignOut() {
    // First clear backend session
    await fetch('https://api.augnos.ch/auth/logout', { credentials: 'include' });
    // Then clear Cognito session
    window.location.href = `https://auth.augnos.ch/logout?client_id=1ct0q58hul3gkslc4pkj60gatk&logout_uri=https://portal.augnos.ch/index.html`;
}

console.log('[Frontend] app.js loaded, initializing...');

document.addEventListener('DOMContentLoaded', async () => {
  console.log('[DOMContentLoaded] Event triggered');
  console.log('[DOMContentLoaded] Current URL:', window.location.href);
  console.log('[Frontend] DOM Content Loaded');

  // Get DOM elements
  const startButton = document.getElementById('startButton');
  const stopButton = document.getElementById('stopButton');
  const usernameElement = document.getElementById('username');

  console.log('[DOMContentLoaded] Elements found:', {
      hasStartButton: !!startButton,
      hasStopButton: !!stopButton,
      hasUsername: !!usernameElement
  });

  // At the start of your app initialization
  if (!navigator.cookieEnabled) {
      // Show message that cookies are required for authentication
      document.body.innerHTML = '<div class="error-message">Please enable cookies to use this application.</div>';
      return;
  }

  // Fetch user info from backend (requires a /auth/userinfo route to exist)
  try {
      const resp = await fetch('https://api.augnos.ch/auth/userinfo', { credentials: 'include' });
      if (resp.ok) {
          const data = await resp.json();
          // Update username placeholder text
          if (usernameElement && data.username) {
              usernameElement.textContent = data.username;
          }
      }
  } catch (error) {
      console.error('[Frontend] Error fetching user info:', error);
  }

  setupUI();

  try {
    // After authentication, check microphone permission
    console.log('[DOMContentLoaded] Checking microphone permissions');
    await checkMicrophonePermission();
  } catch (error) {
    console.log('[DOMContentLoaded] Microphone check error:', error);
    console.error('Error:', error);
    updateConnectionStatus(false);
  }
});

function setupUI() {
  const signOutButton = document.getElementById('signOutButton');
  console.log('Attempting to attach event listener to sign-out button');
  if (signOutButton) {
    console.log('Sign-out button found, attaching event listener');
    signOutButton.addEventListener('click', (event) => {
      event.preventDefault();
      console.log('Sign-out button clicked, calling handleSignOut');
      handleSignOut();
    });
  } else {
    console.error('Sign out button not found in the DOM');
  }
}

// Define checkMicrophonePermission function
async function checkMicrophonePermission() {
    try {
        console.log('Checking microphone permissions...');
        const permissionResult = await navigator.permissions.query({ name: 'microphone' });
        
        console.log('Permission state:', permissionResult.state);
        
        // Listen for permission changes
        permissionResult.addEventListener('change', () => {
            console.log('Permission state changed to:', permissionResult.state);
            document.getElementById('connectionText').textContent = 
                `Microphone permission: ${permissionResult.state}`;
        });

        return {
            success: permissionResult.state === 'granted',
            state: permissionResult.state
        };
    } catch (err) {
        console.error('Permission check error:', err);
        return { success: false, error: err };
    }
}

// Expose it globally
window.checkMicrophonePermission = checkMicrophonePermission;