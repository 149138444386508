console.log('app.js is loading');
import { AWS } from 'aws-sdk';
import { Amplify } from 'aws-amplify';
import { Sha256 } from '@aws-crypto/sha256-js';
import amplifyconfig from './amplifyconfiguration.json';
import { CognitoIdentityClient, GetIdCommand, GetCredentialsForIdentityCommand } from "@aws-sdk/client-cognito-identity";
import { Credentials } from "@aws-sdk/client-sts";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

Amplify.configure(amplifyconfig);

function generateRandomString() {
    const randomValues = new Uint8Array(32);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(b => ('0' + b.toString(16)).slice(-2)).join('');
}

function generateState() {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0].toString(36);
}

async function generateCodeChallenge(codeVerifier) {
    const hashBuffer = await new Sha256().update(codeVerifier).digest();
    const codeChallenge = btoa(String.fromCharCode.apply(null, new Uint8Array(hashBuffer)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
    return codeChallenge;
}

function decodeJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function updateConnectionStatus(connected) {
    const connectionLight = document.getElementById('connectionLight');
    const connectionText = document.getElementById('connectionText');
    connectionLight.className = connected ? 'connection-status connected' : 'connection-status disconnected';
    connectionText.textContent = connected ? 'Connected' : 'Not Connected';
}

function checkTokenStatus() {
  const idToken = localStorage.getItem('idToken');
  const expiration = localStorage.getItem('tokenExpiration');

  if (!idToken || !expiration) {
    console.log('Token or expiration not found in localStorage');
    return { exists: false, expired: true };
  }

  // Rest of the isTokenExpired logic here
  const currentTime = Math.floor(Date.now() / 1000);
  const expirationTime = parseInt(expiration);
  const timeUntilExpiration = expirationTime - currentTime;

  const minutes = Math.floor(timeUntilExpiration / 60);
  const seconds = timeUntilExpiration % 60;

  const isExpired = timeUntilExpiration < 300;

  console.log(`
Token Status:
---------------------------------
Current time:      ${new Date(currentTime * 1000).toLocaleString()}
Expiration time:   ${new Date(expirationTime * 1000).toLocaleString()}
Time until expiry: ${minutes} minutes and ${seconds} seconds
Status:            ${isExpired ? 'Considered expired' : 'Valid'}
---------------------------------`);

  return { exists: true, expired: isExpired };
}

async function refreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
      console.error('No refresh token available');
      return false;
  }

  try {
      const response = await fetch('https://auth.augnos.ch/oauth2/token', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: `grant_type=refresh_token&client_id=1ct0q58hul3gkslc4pkj60gatk&refresh_token=${refreshToken}`
      });

      if (!response.ok) {
          throw new Error(`Token refresh failed: ${response.status}`);
      }

      const tokens = await response.json();
      const idTokenPayload = decodeJwt(tokens.id_token);

      localStorage.setItem('idToken', tokens.id_token);
      localStorage.setItem('accessToken', tokens.access_token);
      localStorage.setItem('tokenExpiration', idTokenPayload.exp);

      console.log('Tokens refreshed successfully');
      return true;
  } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
  }
}

async function fetchPresignedUrl() {
  try {
      const response = await fetch('https://api.augnos.ch/get-presigned-url');
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Pre-signed URL:', data.url);
      
      // Extract and log the X-Amz-Expires value from the URL
      const urlParams = new URLSearchParams(data.url.split('?')[1]);
      const expiresValue = urlParams.get('X-Amz-Expires');
      console.log('X-Amz-Expires value:', expiresValue);
      
      return data.url;
  } catch (error) {
      console.error('Error fetching pre-signed URL:', error);
      throw error;
  }
}


function handleSignOut() {
  console.log('Sign out button clicked');

  const state = generateState();
  sessionStorage.setItem('oauthState', state);

  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('codeVerifier');
  localStorage.removeItem('tokenExpiration');

  const clientId = '1ct0q58hul3gkslc4pkj60gatk';
  const signOutRedirectUri = encodeURIComponent('https://augnos.ch');
  const logoutUrl = `https://auth.augnos.ch/logout?response_type=code&client_id=${clientId}&logout_uri=${signOutRedirectUri}&state=${state}`;
  
  console.log('Preparing to redirect to:', logoutUrl);

  if (process.env.NODE_ENV === 'production') {
    window.location.href = logoutUrl;
  } else {
    console.log('Redirecting in 3 seconds...');
    setTimeout(() => {
      console.log('Redirecting now...');
      window.location.href = logoutUrl;
    }, 3000);
  }
}

document.addEventListener('DOMContentLoaded', async (event) => {
  console.log('DOM beginning to load!');

  setupUI();

  try {
    if (window.location.search.includes('code=')) {
      // We're in the middle of an authentication flow
      await handleAuthentication();
      // Now that handleAuthentication has completed, we can check the token status
      checkTokenStatus();
    } else {
      // We're not in an authentication flow, so we can check the token status
      const tokenStatus = checkTokenStatus();
      if (tokenStatus.exists) {
        if (tokenStatus.expired) {
          console.log('Token is expired or close to expiring. Initiating refresh...');
          const refreshSuccess = await refreshToken();
          if (!refreshSuccess) {
            console.log('Token refresh failed. Redirecting to login page.');
            window.location.href = `https://auth.augnos.ch/login?response_type=code&client_id=1ct0q58hul3gkslc4pkj60gatk&redirect_uri=https://portal.augnos.ch/index.html`;
            return;
          }
        } else {
          console.log('Token is still valid.');
        }
      } else {
        // No tokens found, initiate login process
        await initiateLoginProcess();
      }
    }
  } catch (error) {
    console.error('Authentication error:', error);
    updateConnectionStatus(false);
  }

  console.log('DOM fully loaded');
});

function setupUI() {
  const signOutButton = document.getElementById('signOutButton');
  console.log('Attempting to attach event listener to sign-out button');
  if (signOutButton) {
    console.log('Sign-out button found, attaching event listener');
    signOutButton.addEventListener('click', (event) => {
      event.preventDefault();
      console.log('Sign-out button clicked, calling handleSignOut');
      handleSignOut();
    });
  } else {
    console.error('Sign out button not found in the DOM');
  }
}

async function exchangeCodeForTokens(code, codeVerifier) {
  const response = await fetch(`https://auth.augnos.ch/oauth2/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `grant_type=authorization_code&client_id=1ct0q58hul3gkslc4pkj60gatk&code_verifier=${codeVerifier}&redirect_uri=https://portal.augnos.ch/index.html&code=${code}`
  });

  if (!response.ok) throw new Error(`Token request failed: ${response.status}`);
  return response.json();
}

async function retrieveAwsCredentials() {
  const idToken = localStorage.getItem('idToken');
  if (!idToken) throw new Error('ID Token not found in localStorage');

  const credentialsProvider = fromCognitoIdentityPool({
    clientConfig: { region: amplifyconfig.aws_cognito_region },
    identityPoolId: amplifyconfig.aws_cognito_identity_pool_id,
    logins: {
      [`cognito-idp.${amplifyconfig.aws_cognito_region}.amazonaws.com/${amplifyconfig.aws_user_pools_id}`]: idToken
    }
  });

  return credentialsProvider();
}

async function establishWebSocketConnection() {
  const presignedUrl = await fetchPresignedUrl();
  const socket = new WebSocket(presignedUrl);
  
  // Log the initial state
  console.log('WebSocket created, initial readyState:', socket.readyState);

  // Return the socket
  return socket;
}

async function handleAuthentication() {
  try {
    if (!window.location.search.includes('code=')) {
      await initiateLoginProcess();
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const codeVerifier = localStorage.getItem('codeVerifier');
    
    const tokens = await exchangeCodeForTokens(code, codeVerifier);
    if (!tokens.id_token) throw new Error('ID Token not received from authentication server');

    storeTokens(tokens);
    updateUsername(tokens.id_token);

    const credentials = await retrieveAwsCredentials();
    logCredentials(credentials);

    const socket = await establishWebSocketConnection();
    console.log('Socket received in handleAuthentication, readyState:', socket.readyState);
    handleWebSocketEvents(socket);

    // Add a delay and check the socket state again
    await new Promise(resolve => setTimeout(resolve, 1000));
    console.log('Socket state after 1 second delay:', socket.readyState);

    // Removed: updateConnectionStatus(true);
  } catch (error) {
    console.error('Authentication error:', error);
    // Removed: updateConnectionStatus(false);
    // Instead, we'll log the error and potentially handle it without affecting WebSocket status
    handleAuthenticationError(error);
  }
}

function storeTokens(tokens) {
  const idTokenPayload = decodeJwt(tokens.id_token);
  localStorage.setItem('idToken', tokens.id_token);
  localStorage.setItem('accessToken', tokens.access_token);
  localStorage.setItem('refreshToken', tokens.refresh_token);
  localStorage.setItem('tokenExpiration', idTokenPayload.exp);
}

function updateUsername(idToken) {
  const userInfo = decodeJwt(idToken);
  const username = userInfo['email'] || userInfo['cognito:username'];
  document.getElementById('username').innerText = username;
}

function logCredentials(credentials) {
  console.log('AWS credentials obtained successfully');
  console.log('Access Key ID:', credentials.accessKeyId);
  console.log('Secret Access Key:', credentials.secretAccessKey.substring(0, 5) + '...');
  console.log('Session Token:', credentials.sessionToken.substring(0, 5) + '...');
  window.awsCredentials = credentials;
}

function handleWebSocketEvents(socket) {
  socket.onopen = () => {
    console.log('WebSocket connection opened');
    sendConfigurationMessage(socket);
  };

  socket.onmessage = function(e) {
    const server_message = e.data;
    console.log('Message received from server:', server_message);
    // Handle the server message as needed
    return false; // Prevents the handler from returning immediately
  };

  socket.onerror = (error) => {
    console.error('WebSocket error:', error);
    updateConnectionStatus(false);
  };

  socket.onclose = (event) => {
    console.log('WebSocket connection closed:', event.code, event.reason);
    console.log('Was clean:', event.wasClean);
    updateConnectionStatus(false);
  };
}



async function initiateLoginProcess() {
  const codeVerifier = generateRandomString();
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  localStorage.setItem('codeVerifier', codeVerifier);
  window.location.href = `https://auth.augnos.ch/login?response_type=code&client_id=1ct0q58hul3gkslc4pkj60gatk&redirect_uri=https://portal.augnos.ch/index.html&code_challenge_method=S256&code_challenge=${codeChallenge}`;
}

function handleAuthenticationError(error) {
  console.error('Authentication error:', error.message);
  alert('There was a problem with authentication. Please try logging in again.');
  // Clear any stored authentication data
  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpiration');
  // Redirect to login page
  window.location.href = 'https://auth.augnos.ch/login?response_type=code&client_id=1ct0q58hul3gkslc4pkj60gatk&redirect_uri=https://portal.augnos.ch/index.html';
}

function sendConfigurationMessage() {
  const configMessage = JSON.stringify({
    media_encoding: "pcm",
    sample_rate: 16000,
    language_code: "en-US"
  });

  if (socket.readyState === WebSocket.OPEN) {
    console.log('Sending configuration message:', configMessage);
    socket.send(configMessage);
  } else {
    console.error('WebSocket is not open. ReadyState:', socket.readyState);
  }
}