/*
   Persistent Single-Tab Manager Module with complete BroadcastChannel API usage
*/

import { showModalOverlay } from './modalOverlay.mjs';

const LOCAL_STORAGE_KEY = 'primaryTabId';
const SESSION_DOOMED_KEY = 'doomed';
const CHANNEL_NAME = 'active-tab';

// Generate a unique ID for this tab
const tabId = `${Date.now()}-${Math.random().toString(36).substring(2, 11)}`;
let channel = null;

// First check if this tab is already doomed
if (sessionStorage.getItem(SESSION_DOOMED_KEY) === 'true') {
  // Permanently doomed: show the overlay and stop further initialization
  showModalOverlay();
} else {
  // Create a BroadcastChannel for inter-tab communication
  channel = new BroadcastChannel(CHANNEL_NAME);
  console.log(`[TabManager] Created channel: ${channel.name}`);
  
  // Set up message handler
  channel.onmessage = (event) => {
    console.log(`[TabManager] Received message:`, event.data);
    // Process tab status messages
  };
  
  // Handle message errors
  channel.onmessageerror = (event) => {
    console.error(`[TabManager] Message error:`, event);
  };

  // Check localStorage for an existing primary tab
  const primaryTabId = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!primaryTabId) {
    // No primary exists: mark this tab as primary
    localStorage.setItem(LOCAL_STORAGE_KEY, tabId);
    
    // Announce this tab as primary
    channel.postMessage({
      type: 'PRIMARY_TAB',
      tabId: tabId,
      timestamp: Date.now()
    });
  } else {
    // A primary tab already exists: mark this tab as doomed
    sessionStorage.setItem(SESSION_DOOMED_KEY, 'true');
    showModalOverlay();
  }
}

// Consolidated cleanup handler:
window.addEventListener('beforeunload', () => {
  if (!channel) return;
  
  // If current tab is primary, remove its marker and announce Primary Closing.
  if (localStorage.getItem(LOCAL_STORAGE_KEY) === tabId) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    channel.postMessage({
      type: 'PRIMARY_CLOSING',
      tabId: tabId,
      timestamp: Date.now()
    });
  }
  
  // Broadcast additional cleanup messages.
  channel.postMessage({
    type: 'TAB_CLOSING',
    tabId: tabId,
    timestamp: Date.now()
  });
  channel.postMessage({
    type: 'TAB_UNLOADING',
    tabId: tabId,
    timestamp: Date.now()
  });
  
  // Now safely close the channel.
  channel.close();
  console.log(`[TabManager] Channel closed`);
});

// Expose a function (if needed elsewhere) to determine if this tab is primary.
export function isCurrentTabPrimary() {
  return localStorage.getItem(LOCAL_STORAGE_KEY) === tabId;
}

export { channel, tabId }; 