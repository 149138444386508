// Audio Configuration
export const AUDIO_CONFIG = {
    // Base settings
    CHUNK_DURATION_MS: 128,    // Duration of each chunk in milliseconds
    SAMPLE_RATE: 16000,        // Samples per second
    BUFFER_DURATION_SECONDS: 5, // How many seconds of audio to buffer
    CHUNK_TIMEOUT_MULTIPLIER: 20, // Multiplier for chunk timeout calculation
    
    // Audio format settings
    AUDIO_FORMAT: 1,           // 1 = PCM (linear quantization)
    CHANNELS: 1,               // 1 = Mono, 2 = Stereo
    BITS_PER_SAMPLE: 16,       // 8, 16, 24, or 32 bits per sample
    NUMBER_OF_INPUTS: 1,       // Number of inputs for AudioWorkletNode
    NUMBER_OF_OUTPUTS: 1,      // Number of outputs for AudioWorkletNode
    OUTPUT_CHANNEL_COUNT: [1], // Output channel count (array) for AudioWorkletNode

    // Derived values
    get SAMPLES_PER_CHUNK() {
        const rawSamples = Math.floor(this.SAMPLE_RATE * (this.CHUNK_DURATION_MS / 1000));
        return getNextValidBufferSize(rawSamples);
    },
    
    get BYTES_PER_SAMPLE() {
        return this.BITS_PER_SAMPLE / 8;
    },
    
    get BYTES_PER_CHUNK() {
        return this.SAMPLES_PER_CHUNK * this.BYTES_PER_SAMPLE;
    },

    get CHUNKS_PER_SECOND() {
        return Math.ceil(1000 / this.CHUNK_DURATION_MS);  // Round up to ensure we have enough buffer space
    },
    
    get BUFFER_SIZE() {
        return this.CHUNKS_PER_SECOND * this.BUFFER_DURATION_SECONDS;
    },

    get CHUNK_TIMEOUT_MS() {
        return this.CHUNK_DURATION_MS * this.CHUNK_TIMEOUT_MULTIPLIER;
    },
    
    get BLOCK_ALIGN() {
        return this.CHANNELS * this.BYTES_PER_SAMPLE;
    },
    
    get BYTE_RATE() {
        return this.SAMPLE_RATE * this.BLOCK_ALIGN;
    }
};

// Helper function to round up to the nearest valid buffer size
export function getNextValidBufferSize(size) {
    const validSizes = [256, 512, 1024, 2048, 4096, 8192, 16384];
    for (const validSize of validSizes) {
        if (validSize >= size) {
            return validSize;
        }
    }
    return 16384; // Return largest valid size if input is too large
} 