// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base font for everything */
body, textarea, button, div {
    font-size: 14px;
    font-family: 'Courier New', monospace;
}

/* Top spacing */
#websocketStatus {
    margin-bottom: 8px;
}

#welcome-message {
    margin-bottom: 8px;
}

/* Transcription area */
#transcription-area {
    width: 600px;
    margin-bottom: 5px;  /* Small gap before buttons */
}

#transcription-area > div {
    margin-bottom: 8px;  /* Space between "Transcription:" and textarea */
}

/* Textarea specific styles */
#transcription-text {
    width: 100%;
    height: 300px;
    padding: 10px;
    font-family: 'Courier New', monospace;
    font-size: 12px;  /* Smaller font size for textarea */
    border: 1px solid #ccc;
    resize: none;
    box-sizing: border-box;
    margin-bottom: 5px;
}

/* Final transcript alternating colors */
.transcript-row.color-a {
    color: #222; /* Darker shade */
}

.transcript-row.color-b {
    color: #acacac; /* Lighter shade */
} `, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,eAAe;IACf,qCAAqC;AACzC;;AAEA,gBAAgB;AAChB;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA,uBAAuB;AACvB;IACI,YAAY;IACZ,kBAAkB,GAAG,6BAA6B;AACtD;;AAEA;IACI,kBAAkB,GAAG,gDAAgD;AACzE;;AAEA,6BAA6B;AAC7B;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,qCAAqC;IACrC,eAAe,GAAG,mCAAmC;IACrD,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA,wCAAwC;AACxC;IACI,WAAW,EAAE,iBAAiB;AAClC;;AAEA;IACI,cAAc,EAAE,kBAAkB;AACtC","sourcesContent":["/* Base font for everything */\nbody, textarea, button, div {\n    font-size: 14px;\n    font-family: 'Courier New', monospace;\n}\n\n/* Top spacing */\n#websocketStatus {\n    margin-bottom: 8px;\n}\n\n#welcome-message {\n    margin-bottom: 8px;\n}\n\n/* Transcription area */\n#transcription-area {\n    width: 600px;\n    margin-bottom: 5px;  /* Small gap before buttons */\n}\n\n#transcription-area > div {\n    margin-bottom: 8px;  /* Space between \"Transcription:\" and textarea */\n}\n\n/* Textarea specific styles */\n#transcription-text {\n    width: 100%;\n    height: 300px;\n    padding: 10px;\n    font-family: 'Courier New', monospace;\n    font-size: 12px;  /* Smaller font size for textarea */\n    border: 1px solid #ccc;\n    resize: none;\n    box-sizing: border-box;\n    margin-bottom: 5px;\n}\n\n/* Final transcript alternating colors */\n.transcript-row.color-a {\n    color: #222; /* Darker shade */\n}\n\n.transcript-row.color-b {\n    color: #acacac; /* Lighter shade */\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
