// This module exports a function to display a persistent modal overlay.
// The overlay covers the full viewport and blocks all interactions.
// It instructs the user to close the tab if multiple transcription tabs are open.

export function showModalOverlay() {
  // If the overlay already exists, don't recreate it.
  if (document.getElementById('single-tab-overlay')) {
    return;
  }

  // Create the overlay element
  const overlay = document.createElement('div');
  overlay.id = 'single-tab-overlay';

  // Apply styles to cover the entire viewport
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100vw';
  overlay.style.height = '100vh';
  overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'; // nearly opaque black
  overlay.style.zIndex = '10000'; // high z-index to block all other content
  overlay.style.display = 'flex';
  overlay.style.flexDirection = 'column';
  overlay.style.justifyContent = 'center';
  overlay.style.alignItems = 'center';
  overlay.style.textAlign = 'center';
  overlay.style.color = '#fff';
  overlay.style.fontSize = '1.5rem';
  overlay.style.padding = '20px';

  // Create the message element
  const message = document.createElement('p');
  message.textContent = "Another transcription tab is already open. For security and cost reasons, please close this tab.";
  overlay.appendChild(message);

  // Append the overlay to the document body
  document.body.appendChild(overlay);
} 