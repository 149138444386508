console.log('app.js is loading');
import { AWS } from 'aws-sdk';
import { Amplify } from 'aws-amplify';
import { Sha256 } from '@aws-crypto/sha256-js';
import amplifyconfig from './amplifyconfiguration.json';
import { CognitoIdentityClient, GetIdCommand, GetCredentialsForIdentityCommand } from "@aws-sdk/client-cognito-identity";
import { Credentials } from "@aws-sdk/client-sts";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import crc32 from "crc-32";

// Constants for AWS Event Stream format
const PRELUDE_LENGTH = 8;  // 4 bytes total length + 4 bytes headers length
const PRELUDE_CRC_LENGTH = 4;
const MESSAGE_CRC_LENGTH = 4;
// const CHUNK_SIZE = 1024;   // Audio chunk size for AWS Transcribe

// Constants for audio format
const SAMPLE_RATE = 16000;  // Hz
const AUDIO_DURATION_MS = 100;  // Duration per chunk in ms
const CHANNELS = 1;  // Single channel
const CHUNK_SIZE = Math.floor((AUDIO_DURATION_MS / 1000) * SAMPLE_RATE * 2 * CHANNELS);
// = (0.1 * 16000 * 2 * 1) = 3200 bytes
const silentChunk = generateSilentAudioChunk();  // Create once, reuse

// Buffer to store audio data
let audioBuffer = new Uint8Array(0);  // Keep it as Uint8Array since we're in browser
let frameCount = 0;


Amplify.configure(amplifyconfig);

function generateRandomString() {
    const randomValues = new Uint8Array(32);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(b => ('0' + b.toString(16)).slice(-2)).join('');
}

function generateState() {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0].toString(36);
}

async function generateCodeChallenge(codeVerifier) {
    const hashBuffer = await new Sha256().update(codeVerifier).digest();
    const codeChallenge = btoa(String.fromCharCode.apply(null, new Uint8Array(hashBuffer)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
    return codeChallenge;
}

function decodeJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function updateConnectionStatus(connected) {
    const connectionLight = document.getElementById('connectionLight');
    const connectionText = document.getElementById('connectionText');
    connectionLight.className = connected ? 'connection-status connected' : 'connection-status disconnected';
    connectionText.textContent = connected ? 'Connected' : 'Not Connected';
}

function checkTokenStatus() {
  const idToken = localStorage.getItem('idToken');
  const expiration = localStorage.getItem('tokenExpiration');

  if (!idToken || !expiration) {
    console.log('Token or expiration not found in localStorage');
    return { exists: false, expired: true };
  }

  // Rest of the isTokenExpired logic here
  const currentTime = Math.floor(Date.now() / 1000);
  const expirationTime = parseInt(expiration);
  const timeUntilExpiration = expirationTime - currentTime;

  const minutes = Math.floor(timeUntilExpiration / 60);
  const seconds = timeUntilExpiration % 60;

  const isExpired = timeUntilExpiration < 300;

  console.log(`
Token Status:
---------------------------------
Current time:      ${new Date(currentTime * 1000).toLocaleString()}
Expiration time:   ${new Date(expirationTime * 1000).toLocaleString()}
Time until expiry: ${minutes} minutes and ${seconds} seconds
Status:            ${isExpired ? 'Considered expired' : 'Valid'}
---------------------------------`);

  return { exists: true, expired: isExpired };
}

async function refreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
      console.error('No refresh token available');
      return false;
  }

  try {
      const response = await fetch('https://auth.augnos.ch/oauth2/token', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: `grant_type=refresh_token&client_id=1ct0q58hul3gkslc4pkj60gatk&refresh_token=${refreshToken}`
      });

      if (!response.ok) {
          throw new Error(`Token refresh failed: ${response.status}`);
      }

      const tokens = await response.json();
      const idTokenPayload = decodeJwt(tokens.id_token);

      localStorage.setItem('idToken', tokens.id_token);
      localStorage.setItem('accessToken', tokens.access_token);
      localStorage.setItem('tokenExpiration', idTokenPayload.exp);

      console.log('Tokens refreshed successfully');
      return true;
  } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
  }
}

async function fetchPresignedUrl() {
  try {
      const response = await fetch('https://api.augnos.ch/get-presigned-url');
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Pre-signed URL:', data.url);
      
      // Extract and log the X-Amz-Expires value from the URL
      const urlParams = new URLSearchParams(data.url.split('?')[1]);
      const expiresValue = urlParams.get('X-Amz-Expires');
      console.log('X-Amz-Expires value:', expiresValue);
      
      return data.url;
  } catch (error) {
      console.error('Error fetching pre-signed URL:', error);
      throw error;
  }
}


function handleSignOut() {
  console.log('Sign out button clicked');

  const state = generateState();
  sessionStorage.setItem('oauthState', state);

  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('codeVerifier');
  localStorage.removeItem('tokenExpiration');

  const clientId = '1ct0q58hul3gkslc4pkj60gatk';
  const signOutRedirectUri = encodeURIComponent('https://augnos.ch');
  const logoutUrl = `https://auth.augnos.ch/logout?response_type=code&client_id=${clientId}&logout_uri=${signOutRedirectUri}&state=${state}`;
  
  console.log('Preparing to redirect to:', logoutUrl);

  if (process.env.NODE_ENV === 'production') {
    window.location.href = logoutUrl;
  } else {
    console.log('Redirecting in 3 seconds...');
    setTimeout(() => {
      console.log('Redirecting now...');
      window.location.href = logoutUrl;
    }, 3000);
  }
}

document.addEventListener('DOMContentLoaded', async (event) => {
  console.log('DOM beginning to load!');

  setupUI();

  try {
    if (window.location.search.includes('code=')) {
      // We're in the middle of an authentication flow
      await handleAuthentication();
      // Now that handleAuthentication has completed, we can check the token status
      checkTokenStatus();
    } else {
      // We're not in an authentication flow, so we can check the token status
      const tokenStatus = checkTokenStatus();
      if (tokenStatus.exists) {
        if (tokenStatus.expired) {
          console.log('Token is expired or close to expiring. Initiating refresh...');
          const refreshSuccess = await refreshToken();
          if (!refreshSuccess) {
            console.log('Token refresh failed. Redirecting to login page.');
            window.location.href = `https://auth.augnos.ch/login?response_type=code&client_id=1ct0q58hul3gkslc4pkj60gatk&redirect_uri=https://portal.augnos.ch/index.html`;
            return;
          }
        } else {
          console.log('Token is still valid.');
        }
      } else {
        // No tokens found, initiate login process
        await initiateLoginProcess();
      }
    }
  } catch (error) {
    console.error('Authentication error:', error);
    updateConnectionStatus(false);
  }

  console.log('DOM fully loaded');
});

function setupUI() {
  const signOutButton = document.getElementById('signOutButton');
  console.log('Attempting to attach event listener to sign-out button');
  if (signOutButton) {
    console.log('Sign-out button found, attaching event listener');
    signOutButton.addEventListener('click', (event) => {
      event.preventDefault();
      console.log('Sign-out button clicked, calling handleSignOut');
      handleSignOut();
    });
  } else {
    console.error('Sign out button not found in the DOM');
  }
}

async function exchangeCodeForTokens(code, codeVerifier) {
  const response = await fetch(`https://auth.augnos.ch/oauth2/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `grant_type=authorization_code&client_id=1ct0q58hul3gkslc4pkj60gatk&code_verifier=${codeVerifier}&redirect_uri=https://portal.augnos.ch/index.html&code=${code}`
  });

  if (!response.ok) throw new Error(`Token request failed: ${response.status}`);
  return response.json();
}

async function retrieveAwsCredentials() {
  const idToken = localStorage.getItem('idToken');
  if (!idToken) throw new Error('ID Token not found in localStorage');

  const credentialsProvider = fromCognitoIdentityPool({
    clientConfig: { region: amplifyconfig.aws_cognito_region },
    identityPoolId: amplifyconfig.aws_cognito_identity_pool_id,
    logins: {
      [`cognito-idp.${amplifyconfig.aws_cognito_region}.amazonaws.com/${amplifyconfig.aws_user_pools_id}`]: idToken
    }
  });

  return credentialsProvider();
}

async function establishWebSocketConnection() {
  const presignedUrl = await fetchPresignedUrl();

  // Log the presigned URL
  console.log('Presigned URL in establishWebSocketConnection:', presignedUrl);

  const socket = new WebSocket(presignedUrl);
  
  // Log the initial state
  console.log('WebSocket created, initial readyState:', socket.readyState);

  // Return the socket
  return socket;
}

async function handleAuthentication() {
  try {
    if (!window.location.search.includes('code=')) {
      await initiateLoginProcess();
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const codeVerifier = localStorage.getItem('codeVerifier');
    
    const tokens = await exchangeCodeForTokens(code, codeVerifier);
    if (!tokens.id_token) throw new Error('ID Token not received from authentication server');

    storeTokens(tokens);
    updateUsername(tokens.id_token);

    const credentials = await retrieveAwsCredentials();
    logCredentials(credentials);

    const socket = await establishWebSocketConnection();
    console.log('Socket received in handleAuthentication, readyState:', socket.readyState);
    handleWebSocketEvents(socket);

    // Add a delay and check the socket state again
    await new Promise(resolve => setTimeout(resolve, 1000));
    console.log('Socket state after 1 second delay:', socket.readyState);

    // Removed: updateConnectionStatus(true);
  } catch (error) {
    console.error('Authentication error:', error);
    // Removed: updateConnectionStatus(false);
    // Instead, we'll log the error and potentially handle it without affecting WebSocket status
    handleAuthenticationError(error);
  }
}

function storeTokens(tokens) {
  const idTokenPayload = decodeJwt(tokens.id_token);
  localStorage.setItem('idToken', tokens.id_token);
  localStorage.setItem('accessToken', tokens.access_token);
  localStorage.setItem('refreshToken', tokens.refresh_token);
  localStorage.setItem('tokenExpiration', idTokenPayload.exp);
}

function updateUsername(idToken) {
  const userInfo = decodeJwt(idToken);
  const username = userInfo['email'] || userInfo['cognito:username'];
  document.getElementById('username').innerText = username;
}

function logCredentials(credentials) {
  console.log('AWS credentials obtained successfully');
  console.log('Access Key ID:', credentials.accessKeyId);
  console.log('Secret Access Key:', credentials.secretAccessKey.substring(0, 5) + '...');
  console.log('Session Token:', credentials.sessionToken.substring(0, 5) + '...');
  window.awsCredentials = credentials;
}

function handleWebSocketEvents(socket) {

  socket.onopen = () => {
      console.log('[WS] Connection opened');
      console.log('[WS] Sending START_REQUEST...');
      sendConfigurationMessage(socket);
      console.log('[WS] Starting silent audio transmission...');
      updateConnectionStatus(true);
  };

  socket.onmessage = async function (e) {
      console.log('[WS] Message received');
      const serverMessage = e.data;
      if (serverMessage instanceof Blob) {
          try {
              const arrayBuffer = await serverMessage.arrayBuffer();
              const message = new Uint8Array(arrayBuffer);
              const decodedFrame = decodeEventStreamFrame(message);
              console.log('[WS] Decoded Frame:', decodedFrame);
          } catch (error) {
              console.error('[WS] Error decoding message:', error);
          }
      } else {
          console.log('[WS] Non-blob message:', serverMessage);
      }
  };

  socket.onerror = (error) => {
      console.error('[WS] Error:', error);
      updateConnectionStatus(false);
  };

  socket.onclose = (event) => {
      console.log('[WS] Connection closed:', event.code, event.reason);
      console.log('[WS] Clean close:', event.wasClean);
      if (audioInterval) {
          console.log('[Audio] Clearing interval due to close');
          clearInterval(audioInterval);
      }
      updateConnectionStatus(false);
  };
}



async function initiateLoginProcess() {
  const codeVerifier = generateRandomString();
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  localStorage.setItem('codeVerifier', codeVerifier);
  window.location.href = `https://auth.augnos.ch/login?response_type=code&client_id=1ct0q58hul3gkslc4pkj60gatk&redirect_uri=https://portal.augnos.ch/index.html&code_challenge_method=S256&code_challenge=${codeChallenge}`;
}

function handleAuthenticationError(error) {
  console.error('Authentication error:', error.message);
  alert('There was a problem with authentication. Please try logging in again.');
  // Clear any stored authentication data
  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpiration');
  // Redirect to login page
  window.location.href = 'https://auth.augnos.ch/login?response_type=code&client_id=1ct0q58hul3gkslc4pkj60gatk&redirect_uri=https://portal.augnos.ch/index.html';
}

function createAudioFrame(audioData) {
  console.log('[Frame] Creating audio frame:', frameCount++);
  
  const headers = [
      { key: ':content-type', value: 'application/octet-stream' },
      { key: ':event-type', value: 'AudioEvent' },
      { key: ':message-type', value: 'event' }
  ];

  const encodedHeaders = encodeHeaders(headers);
  const totalLength = PRELUDE_LENGTH + PRELUDE_CRC_LENGTH + encodedHeaders.length + audioData.length + MESSAGE_CRC_LENGTH;
  
  const prelude = new ArrayBuffer(PRELUDE_LENGTH);
  const preludeView = new DataView(prelude);
  preludeView.setUint32(0, totalLength, false);
  preludeView.setUint32(4, encodedHeaders.length, false);

  const preludeCRC = crc32.buf(new Uint8Array(prelude));
  const messageContent = new Uint8Array(PRELUDE_LENGTH + encodedHeaders.length + audioData.length);
  messageContent.set(new Uint8Array(prelude), 0);
  messageContent.set(encodedHeaders, PRELUDE_LENGTH);
  messageContent.set(audioData, PRELUDE_LENGTH + encodedHeaders.length);

  const messageCRC = crc32.buf(messageContent);
  const message = new Uint8Array(totalLength);
  let offset = 0;

  message.set(new Uint8Array(prelude), offset);
  offset += PRELUDE_LENGTH;
  
  message.set(new Uint8Array([
      (preludeCRC >>> 24) & 0xFF,
      (preludeCRC >>> 16) & 0xFF,
      (preludeCRC >>> 8) & 0xFF,
      preludeCRC & 0xFF
  ]), offset);
  offset += PRELUDE_CRC_LENGTH;

  message.set(encodedHeaders, offset);
  offset += encodedHeaders.length;

  message.set(audioData, offset);
  offset += audioData.length;

  message.set(new Uint8Array([
      (messageCRC >>> 24) & 0xFF,
      (messageCRC >>> 16) & 0xFF,
      (messageCRC >>> 8) & 0xFF,
      messageCRC & 0xFF
  ]), offset);

  console.log('[Frame] Created frame:', {
      frameNumber: frameCount,
      totalLength,
      headersLength: encodedHeaders.length,
      audioLength: audioData.length
  });

  return message;
}



function sendConfigurationMessage(socket) {
  const configPayload = JSON.stringify({
      media_encoding: "pcm",
      sample_rate: 16000,
      language_code: "en-US",
  });

  const payloadBytes = new TextEncoder().encode(configPayload);

  // Required headers for START_REQUEST
  const headers = [
      { key: ':content-type', value: 'application/json', type: 7 },
      { key: ':event-type', value: 'START_REQUEST', type: 7 },
      { key: ':message-type', value: 'event', type: 7 },
  ];

  // Encode headers
  const headersBytes = encodeHeaders(headers);

  // Prelude (8 bytes)
  const totalLength = 8 + headersBytes.length + payloadBytes.length + 4; // Headers + Payload + Message CRC
  const prelude = new ArrayBuffer(8);
  const preludeView = new DataView(prelude);
  preludeView.setUint32(0, totalLength, false); // Total length (4 bytes)
  preludeView.setUint32(4, headersBytes.length, false); // Headers length (4 bytes)

  // CRC for Prelude
  const preludeCRC = crc32.buf(new Uint8Array(prelude));

  // Message CRC (headers + payload)
  const messageContent = new Uint8Array(
      8 + headersBytes.length + payloadBytes.length
  );
  messageContent.set(new Uint8Array(prelude), 0); // Add Prelude
  messageContent.set(headersBytes, 8); // Add Headers
  messageContent.set(payloadBytes, 8 + headersBytes.length); // Add Payload

  const messageCRC = crc32.buf(messageContent);

  // Combine everything into the final message
  const message = new Uint8Array(totalLength + 4);
  message.set(new Uint8Array(prelude), 0); // Add Prelude
  message.set(new Uint8Array([preludeCRC >>> 24, (preludeCRC >>> 16) & 0xff, (preludeCRC >>> 8) & 0xff, preludeCRC & 0xff]), 8); // Add Prelude CRC
  message.set(headersBytes, 12); // Add Headers
  message.set(payloadBytes, 12 + headersBytes.length); // Add Payload
  message.set(new Uint8Array([messageCRC >>> 24, (messageCRC >>> 16) & 0xff, (messageCRC >>> 8) & 0xff, messageCRC & 0xff]), totalLength); // Add Message CRC

  console.log('START_REQUEST Frame (Uint8Array):', message);
  console.log('Frame as Hex:', Array.from(message).map(byte => byte.toString(16).padStart(2, '0')).join(' '));

  if (socket.readyState === WebSocket.OPEN) {
      console.log('Sending START_REQUEST frame...');
      socket.send(message);
  } else {
      console.error('WebSocket is not open. ReadyState:', socket.readyState);
  }
}

function encodeHeaders(headers) {
  console.log('[Headers] Encoding headers:', headers);
  const encoder = new TextEncoder();
  const encodedHeaders = [];
  let totalLength = 0;

  headers.forEach(header => {
      const nameBytes = encoder.encode(header.key);
      const valueBytes = encoder.encode(header.value);
      const headerLength = 1 + nameBytes.length + 1 + 2 + valueBytes.length;
      totalLength += headerLength;
      encodedHeaders.push({ nameBytes, valueBytes, headerLength });
  });

  const combined = new Uint8Array(totalLength);
  let offset = 0;

  encodedHeaders.forEach(({ nameBytes, valueBytes }) => {
      combined[offset++] = nameBytes.length;
      combined.set(nameBytes, offset);
      offset += nameBytes.length;
      combined[offset++] = 7;
      combined[offset++] = (valueBytes.length >> 8) & 0xFF;
      combined[offset++] = valueBytes.length & 0xFF;
      combined.set(valueBytes, offset);
      offset += valueBytes.length;
  });

  console.log('[Headers] Encoded size:', totalLength);
  return combined;
}

// Generate one silent chunk
function generateSilentAudioChunk() {
  const numSamples = CHUNK_SIZE / 2;  // 16-bit = 2 bytes per sample
  const int16Array = new Int16Array(numSamples);  // Zeros by default
  return new Uint8Array(int16Array.buffer);
}

// Function to send silent audio frames repeatedly
function startSendingSilentAudio(socket) {
  let cycleCount = 0;

  async function streamSilentAudio() {
      while (socket.readyState === WebSocket.OPEN) {
          // Buffer accumulation
          const newChunk = silentChunk;
          audioBuffer = concatUint8Arrays(audioBuffer, newChunk);
          
          while (audioBuffer.length >= CHUNK_SIZE) {
              cycleCount++;
              const chunk = audioBuffer.slice(0, CHUNK_SIZE);
              const audioFrame = createAudioFrame(chunk);
              
              console.log('Message Structure:', {
                  'PRELUDE': {
                      'Total Length (4 bytes)': Array.from(audioFrame.slice(0, 4)).map(b => b.toString(16).padStart(2, '0')).join(' '),
                      'Headers Length (4 bytes)': Array.from(audioFrame.slice(4, 8)).map(b => b.toString(16).padStart(2, '0')).join(' '),
                      'Prelude CRC (4 bytes)': Array.from(audioFrame.slice(8, 12)).map(b => b.toString(16).padStart(2, '0')).join(' ')
                  },
                  'DATA': {
                      'Headers (88 bytes)': Array.from(audioFrame.slice(12, 12 + 88)).map(b => b.toString(16).padStart(2, '0')).join(' '),
                      'Payload (3200 bytes)': `First 16 bytes: ${Array.from(audioFrame.slice(12 + 88, 12 + 88 + 16)).map(b => b.toString(16).padStart(2, '0')).join(' ')}...`,
                      'Message CRC (4 bytes)': Array.from(audioFrame.slice(-4)).map(b => b.toString(16).padStart(2, '0')).join(' ')
                  },
                  'Summary': {
                      'Total bytes': audioFrame.length,
                      'Cycle': cycleCount
                  }
              });
              
              socket.send(audioFrame);
              audioBuffer = audioBuffer.slice(CHUNK_SIZE);
          }
      }
  }

  streamSilentAudio().catch(error => {
      console.error('[Audio] Streaming error:', error);
  });
}

function concatUint8Arrays(a, b) {
  const c = new Uint8Array(a.length + b.length);
  c.set(a);
  c.set(b, a.length);
  return c;
}

function decodeEventStreamFrame(message) {
  try {
      const dataView = new DataView(message.buffer);
      const totalLength = dataView.getUint32(0, false);
      const headersLength = dataView.getUint32(4, false);
      const preludeCrc = dataView.getUint32(8, false);

      const headers = [];
      let offset = 12;
      const headerEndOffset = offset + headersLength;

      while (offset < headerEndOffset) {
          // Read name length (1 byte)
          const nameLength = message[offset++];
          
          // Read name
          const name = new TextDecoder().decode(message.slice(offset, offset + nameLength));
          offset += nameLength;

          // Read value type (1 byte)
          const valueType = message[offset++];

          // Read value length (2 bytes)
          const valueLength = (message[offset] << 8) | message[offset + 1];
          offset += 2;

          // Read value
          const value = new TextDecoder().decode(message.slice(offset, offset + valueLength));
          offset += valueLength;

          headers.push({ name, value });
      }

      // Decode payload
      const payloadStart = 12 + headersLength;
      const payloadLength = totalLength - headersLength - 16;
      const payloadText = new TextDecoder().decode(message.slice(payloadStart, payloadStart + payloadLength));
      const payload = JSON.parse(payloadText);

      return {
          headers,
          payload,
          totalLength,
          headersLength,
          preludeCrc,
          messageCrc: dataView.getUint32(totalLength - 4, false)
      };
  } catch (error) {
      console.error('Error decoding event stream frame:', error);
      throw error;
  }
}